<template>
  <ul class="board_list">
    <li class="no_data" v-if="this.notice_data.length <= 0">
      {{ this.$t("common.no_data") }}
    </li>
    <li
      v-for="(value, index) in this.notice_data"
      :key="index"
      @click="this.click_board(value)"
    >
      <div class="title">{{ value.board_title }}</div>
      <div class="date">
        {{
          this.common.date_long_format(
            this.common.date_parse(value.register_date)
          )
        }}
      </div>
    </li>
  </ul>

  <div class="button_wrap">
    <button
      class="button small compact sharp"
      @click="this.load_notice_data"
      v-if="!this.data_fully_loaded"
    >
      {{ this.$t("common.load_more") }}
    </button>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "BoardList",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.notice"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.notice"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      data_fully_loaded: false,
      search_page: 1,
      notice_data: [],
      total_count: 0,
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_notice_data();
  },
  methods: {
    load_notice_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/board/list",
          {
            board_type: "NOTICE",
            board_country: self.common.get_country_code_from_url(),
            page: self.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (self.search_page <= 1) {
            self.notice_data = [];
          }

          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            self.notice_data.push(data[i]);
          }

          if (data.length <= 0) {
            self.data_fully_loaded = true;
          } else {
            self.search_page++;
          }

          // 총 갯수 정보 헤더에서 가져오기
          try {
            let str_info = response.headers.wkt_extra_info;
            let extra_info = JSON.parse(str_info);
            self.total_count = parseInt(extra_info.total_count);
          } catch (e) {
            console.log(e);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //!-- axios
    }, //-- load_notice_data

    click_board: function (board_data) {
      this.$router.push("/notice/" + board_data.board_id.toString());
    },
  },
};
</script>

<style scoped>
.board_list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  border-top: 1px solid #dedede;
}
.board_list > li {
  padding: 1rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.board_list > li.no_data {
  text-align: center;
  padding: 4rem 0;
  cursor: inherit;
}

.board_list > li > .title {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 0 0.4rem 0;
}
.board_list > li > .date {
  font-size: 1rem;
  font-weight: 300;
  color: #808080;
}

.button_wrap {
  margin: 1rem 0;
  text-align: center;
}
</style>
